/* Footer.css */


/* .Footer {
  position: fixed;
left: 0;
bottom: 0;
} */
.bg-footer{
  background-color: black;
  margin-top: auto;
}
.CustomContainer-fluid{ 
  display: flex;
  justify-content: space-between;
  align-items: center; 
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.left-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.linkItem{ 
  margin-right: 10px;
  color: #ffffff;
  text-decoration: none;
}

.disclaimer {
  text-align: center;
}
.app-m-f img{
  max-width: 30vw;
}
.right-images img {
  margin-left: 10px; 
  width: 100px;
  height: auto; 
  display: block;
}
.nav-link {
  color: whitesmoke;
  position: relative;
  overflow: hidden;
  text-decoration: none; /* Supprime le soulignement des liens */
}

.nav-link::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: rgb(218, 143, 4);
  transition: width 0.3s ease;
}

.nav-link:hover::before {
  width: 100%;
}

.nav-link:hover {
  color: whitesmoke;
}

.nav-link:hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(218, 143, 4);
  animation: slide-in 0.3s ease; /* Ajoute une animation de transition */
}
.logo-footer{
  width: 10%;
  border-radius: 50%;
  margin-top: 5px;
}
@media (min-width: 768px){
  .mobile-footer{
    display: none;
  }
}
@media (max-width: 768px){
  .desktop-footer{
    display: none;
  }
  .logo-footer{
    width: 20%;
    padding-top: 10px;
    margin-bottom: 20px;
  }
  .f-divider{
    border-bottom: 3px solid rgb(218, 143, 4);
  }
  .mobile-footer h5{
    margin-bottom: 0;
    padding-bottom: 10px;
    margin-top: 25px;
  }
  .f-title-divider{
    margin-top: 8px;
    margin-bottom: 19px;
    width: 100px;
    height: 1px;
    background: whitesmoke;
  }
  .legales .linkItem{
    font-size: smaller;
    color: white !important;
  }
  .icone-f{
    font-size: 25px;
  }
  .droits{
    color: rgb(219, 216, 216);
  }
  .hr-f{
    color: rgb(219, 216, 216);
  }
  /* .app-m-f img{
    width: 200px;
    height: auto;
    margin-left: 5px;
  } */
}