.TypeReservation {
    /* display: flex;
    justify-content: center;
    margin: 1%;
    gap: 20px; */
    margin: 50px 0;
    text-align: center;
}
.TypeReservation h1{
    margin-bottom: 0.5em;
}
.TypeReservation p{
    margin-top: 1em;
    font-size: 14px;
}
.TypeReservation h3{
    margin-bottom: 1rem;
}
.Reservation {
    width: 35vw;
}
.img-type-r{
    border-radius: 5%;
}
.h1{
    color: #D1A553;
}


.submitBtn {
    background: #D1A553;
    border-radius: 8px;
    border: none;
    width: 250px; 
    height: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 18px 0;
}

/* img{
    max-width: 30vw;
} */
@media (max-width: 768px) {
    .mb{
        margin-bottom: 50px;
    }
    .submitBtn{
        width: 80%;
    }
    .TypeReservation #divider-none{
        margin: 35px auto 35px auto;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    /* Styles pour les tablettes */
    /* Par exemple, ajout d'un padding-bottom */
    .mb {
        margin-bottom: 50px;
    }
    .submitBtn{
        width: 80%;
    }
}

/* .souligne{
    text-decoration: underline;
    text-decoration-color: rgb(238, 170, 43);
    margin-bottom: 50px;
} */
.TypeReservation h1{
    margin-top: 20px;
}