.head{
    height: 70px;
}
.bg-noir{
    background-color: black;
}
.orange{
    color: rgb(218, 143, 4);
}
/* .ico svg:hover{
    color: black !important;
    background-color: rgb(218, 143, 4) !important;
} */
.barre .ico{
    font-size: 20px;
}
.ico:hover{
    color: rgb(218, 143, 4);
    border-top: 1px solid rgb(218, 143, 4);
}
/* i{
    font-size: larger;
} */
em{
    font-size: larger;
}

li a{
    color: whitesmoke;
    /* padding-bottom: 2px !important; */
}
.custom-drop{
    color: whitesmoke;
    font-size: 21px;
    position: relative;
    overflow: hidden;
    text-decoration: none;
  }
.navbar-nav li.nav-item a.nav-link {
    color: whitesmoke;
    font-size: 21px;
    position: relative;
    overflow: hidden;
    text-decoration: none; /* Supprime le soulignement des liens */
  }
  
  .navbar-nav li.nav-item a.nav-link::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: rgb(218, 143, 4);
    transition: width 0.3s ease;
  }
  
  .navbar-nav li.nav-item a.nav-link:hover::before {
    width: 100%;
  }
  
  .navbar-nav li.nav-item a.nav-link:hover {
    color: whitesmoke;
  }
  
  .navbar-nav li.nav-item a.nav-link:hover::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: rgb(218, 143, 4);
    animation: slide-in 0.3s ease;
  }
  .navbar-dark .navbar-toggler-icon {
        background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"%3e%3cpath stroke="rgba(218, 143, 4)" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"/%3e%3c/svg%3e');
    
}
.navbar-toggler{
    border: none;
}
.dropdown-menu.show{
    background: rgba(0, 0, 0);
    border: 1px solid rgb(218, 143, 4);
    color: white;
}
.dropdown-menu.show a{
    color: white;
}
.dropdown-menu.show a:hover{
    background: rgb(218, 143, 4);
}
  
  @keyframes slide-in {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  /* .navbar-collapse{
    position: absolute;
    z-index: 1;
} */
/* .navbar-toggler {
    position: relative;
    z-index: 2;
} */
.no-padding{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.dropdown-menu a:active{
    background: rgb(218, 143, 4);
    color: white;
}
@media (max-width: 991.2px) {
    .barre{
        display: none;
    }
    .img-logo{
        display: none;
    }
    .img-logo-mobile{
        width: 15%;
        border-radius: 50%;
        padding-bottom: 8px;
    }
    .nav-mobile{
        border-bottom: 1px solid rgb(218, 143, 4);
        align-items: center !important;
        justify-content: space-around;
        text-align: center;
    }
    .navbar-toggler-container{
        position: relative;
        z-index: 2;
    }
    nav{
        padding-bottom: 0 !important;
        justify-content: space-around;
    }
    .link-mobile{
        flex-direction: column;
        text-align: center;
    }
    .navbar-nav li.nav-item a.nav-link {
        color: whitesmoke;
        font-size: 18px;
        position: relative;
        overflow: hidden;
        text-decoration: none; /* Supprime le soulignement des liens */
        padding: 8px 0;
    }
    .navbar-nav li.nav-item a.custom-drop{
        color: whitesmoke;
        font-size: 18px;
        position: relative;
        overflow: hidden;
        text-decoration: none; /* Supprime le soulignement des liens */
        padding: 8px 0;
    }
    .navbar-nav{
        margin: 10px 0;
    }
    .slog{
        font-size: 15px;
        font-weight: 500;
    }
    .nav-m-none{
        display: none;
    }
}
@media (min-width : 991.3px) {
    .barre{
        height: 70px;
        /* display: flex;
        align-items: center; */
        position: relative;
        z-index: 1;
    }
    .img-logo{
        width: 9%;
        /* border-radius: 50%; */
        /* overflow: hidden; */
        position: absolute;
    }
    .image-container {
        position: relative;
        display: inline-block;
    }
    .image-container::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); 
    }
    .logo{
        position: absolute;
        margin-top: -50px;
        z-index: 1;
    }
    .img-logo-mobile{
        display: none;
    }
    .nav-mobile{
        display: none;
    }
    .slog{
        display: none;
    }
    nav{
        border-top: 1px solid rgb(218, 143, 4);
        /* box-shadow: 0px 2px 5px 0px rgb(218, 143, 4) */
    }
    .nav-d-none{
        display: none;
    }
}
.close-icon{
    color: transparent;
    text-shadow: 0 0 0 rgb(218, 143, 4);
}
