.Form {
  min-height: 75vh;
}

.iconBack {
  color: #D1A553;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  border: #D1A553 2px solid;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 8%;
  /* left: 0;
  top: 0; */
  /* box-shadow: 0 4px 15px 0 rgba(254, 192, 76, 0.75); */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
}

.iconBack:hover {
  background-position: 100% 0;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  margin-right: 15px;
}

.back-hover:hover {

  text-decoration: underline;
}

#reservation-section .custom-h2 {
  font-size: 18px;
  color: rgb(218, 143, 4);
}

#reservation-section h4 {
  color: #fff;
}

.react-datepicker {
  width: 600px;
  margin-top: 35px;
  /* margin-left: 15vw; */
  /* height: 100%; */
}

.selected-date {
  background-color: #D1A553;

}

.react-datepicker__header {
  background-color: black;
  color: white;
}

.react-datepicker__month-container {
  width: 100%;
  /* background-color: black; */
}

.react-datepicker__week {
  display: flex;
  justify-content: space-around;

}

.react-datepicker__day {
  color: black;
  background-color: white;
  border: 2px solid rgb(218, 143, 4);
  border-radius: 5%;
  flex: 1;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-around;
  border-top: 2px solid rgb(238, 170, 43);
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  color: white;
  font-weight: bold;
}

.react-datepicker__current-month {
  padding-bottom: 1rem;

}

.react-datepicker__navigation-icon--next::before,
.react-datepicker__navigation-icon--previous::before {
  border-color: rgb(218, 143, 4);
}

.disabled-date {
  color: #3b3939;
  text-decoration: line-through;
  text-decoration-color: rgb(0, 0, 0);
  background-color: #c4c1c1;
}


.selected-date,
.react-datepicker__day--selected {
  /* background-color: #D1A553; */
  transition: 0.2s;
  color: #fff;
  font-weight: bold;
  animation: ease-out 0.2s;
  background-image: linear-gradient(to right, rgb(230, 177, 126), rgb(244, 182, 67), rgb(238, 170, 43), rgb(218, 143, 4));
}

.react-datepicker__day--selected:hover {
  background-color: #D1A553;
}

.step-one .h2 {
  color: white;
}

.btn-add-p {
  background: #D1A553;
  border-radius: 8px;
  border: none;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;

  border: none;
  background-size: 300% 100%;
}

.btn-add-p:hover {
  background-position: 100% 0;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  color: white;
}

.btn-add-p:focus {
  outline: none;
}

.btn-add-p {
  background-image: linear-gradient(to right, rgb(230, 177, 126), rgb(244, 182, 67), rgb(238, 170, 43), rgb(218, 143, 4));
  box-shadow: 0 4px 15px 0 rgba(254, 192, 76, 0.75);
}

.btn-text {
  margin-right: 8px;
  /* Ajustez cette valeur pour définir l'espace souhaité entre l'icône et le texte */
}

.btn-icon-p {
  font-size: larger;
}

.btn-chair {
  border: none;
  background-color: transparent;
  color: #fff;
  display: flex;
  flex-direction: column-reverse;

}

.TbArmchair2-icon {
  width: 35px;
  font-weight: bold;
}

.TbArmchair2Off-icon {
  width: 35px;
  font-weight: bold;
}

.Form {
  /* display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; */
  width: 100vw;


}

.mainContainer {
  display: flex;
  justify-content: center;
  /* flex-direction:; */
  align-items: center;

  /* width: 75vw; */
  background-color: black;
  color: white;
  margin-top: 5vh;
  border-radius: 8px;
  padding: 1rem;
  padding-top: 0 !important;
  border-radius: 8px;
}

/* .customContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 70vw;
    background-color: black;
} */
.container-resume {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bottomContainer {
  /* display: flex;
    flex-wrap: nowrap; */
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
    /* width: 80%; */
    /* background-color: black; */
}

.next {
  margin-left: 30px;
}

/* .cardContainer{
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;

    width: 70vw;
    padding: 1rem;
}  */
.cardContainer .selected {
  background-color: rgb(238, 170, 43);
  color: white;
  border: 4px solid white;
}
.custom-card{
    border: 1px solid black;
    /* margin-left: 20px; */
    width: 80px;
    width: 100%;
    height: 10vh;
    border-radius: 8px;
    cursor: pointer;
    color: #000;

}

.seat-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 1rem;
}

/* .seat-row {
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .seat-row .selected{
    border: none;
  }
  .seat-col{
    justify-content: center;
    display: flex;
  }
  .cardSeat {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 100%;
    margin: 5px;
    /* cursor: pointer; */
    font-weight: bold;
  }
  /* .seat-container .col{
    padding: 0;
  } */
button .cardSeat {
  padding: 0;
  margin: 0;
}

.cardSeat.selected {
  background-color: transparent;

  color: rgb(238, 170, 43);
  ;
}

.seat-label {
  font-size: 15px;
}

span .seat-label .selected {
  color: white;
}


.selected {
  background-color: #f5f5f5;
  font-weight: bold;
  border: 2px solid black;
}

.container-m-bar {
  display: block;
}

.customProgress-bar {
  display: none;
}

.progress-step {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 80px;
  height: 60px;
  border-radius: 15%;
  gap: 10px;
  background-color: #eaeaea;
  color: #666;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.progress-step.active {
  background-color: #D1A553;
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
  background-size: 300% 100%;
  background-image: linear-gradient(to right, rgb(230, 177, 126), rgb(244, 182, 67), rgb(238, 170, 43), rgb(218, 143, 4));
  box-shadow: 0 4px 15px 0 rgba(254, 192, 76, 0.75);
}

.progress-step.active:hover {
  background-position: 100% 0;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  color: white;
}

.progress-step.active:focus {
  outline: none;
}


.progress-step.completed {
  background-image: linear-gradient(to right, rgb(230, 177, 126), rgb(244, 182, 67), rgb(238, 170, 43), rgb(218, 143, 4));
  box-shadow: 0 4px 15px 0 rgba(254, 192, 76, 0.75);
  color: #fff;
  background-size: 300% 100%;
  font-weight: bold;
}

.step-circle {
  font-size: 18px;
}

.step-label {
  font-size: 12px;
  text-align: center;
}


.progress-step:not(:last-child) {
  margin-right: 20px;
}

.progress-bar-line:not(:last-child) {
  margin-right: -10px;
}

.card h5 {
  color: #D1A553;
}

.formUserInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  /* padding: 0.5rem; */


}

/* .formInput{
    border-radius: 8px;
    padding: 0.5rem;
    max-height: 40px;
  } */

  .btn-add-p{
    border: 2px solid rgb(218, 143, 4);
    color: rgb(218, 143, 4);
    font-weight: bold;
    height: 50px;
  }

.deleteBtn {
  color: transparent;
  text-shadow: 0 0 0 red;
  background: transparent;
}

.step-one .deleteBtn {
  font-size: 14px;
  padding-left: 10px;
}

/* .btnContainer{
    display: flex;
    flex-direction: column;
    gap: 5px;
  } */

.resume {
  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */
  width: 100%;
  padding: 1rem;
}

.detail {
  background-color: black;
  border: 1px solid white;
  color: whitesmoke;
  -webkit-box-shadow: 4px 5px 25px 5px rgba(0, 0, 0, 0.79);
  box-shadow: 4px 5px 25px 5px rgba(0, 0, 0, 0.79);
  border-radius: 8px;
  height: fit-content;

}

.customContent {
  padding: 0.5rem;
}

.detailHeader {
  padding: 1rem;
  background-image: linear-gradient(to right, rgb(230, 177, 126), rgb(244, 182, 67), rgb(238, 170, 43), rgb(218, 143, 4));
  /* box-shadow: 0 4px 15px 0 rgba(254, 192, 76, 0.75); */
  color: #fff;
  background-size: 300% 100%;
  border-radius: 8px;
}

/* .mainContainer{
    background-color: white!important;
    color: black!important;
    -webkit-box-shadow: 4px 5px 25px 5px rgba(0,0,0,0.79); 
    box-shadow: 4px 5px 25px 5px rgba(0,0,0,0.79);
  } */
.form-reservation {
  margin-top: 40px
}

.reservation-f h1 {
  margin-bottom: 30px;
  margin-top: 35px;
}

#reservation-formulaire {
  font-size: 20px;
  background: rgb(24, 23, 23);
  padding: 10px 40px 40px 40px;
  border-radius: 10px;
  /* box-shadow: 0px 20px 18px -6px rgba(0,0,0,0.5); */
}

#reservation-section label {
  color: #fff;
}

.form-reservation .customBtn {
  height: 40px;
  width: 200px;
}

#reservation-section select {
  height: 50px;
}

#reservation-section input {
  margin-bottom: 25px;
}

.chevron-right svg {
  margin-left: 10px;
}

.chevron-left svg {
  margin-right: 10px;
}

.btn-chair:disabled .TbArmchair2Off-icon {
  fill: gray;
}

@media only screen and (max-width:1025px) {
  .Form>h1 {
    font-size: 20px;
  }

  .custom-h2 {
    font-size: 16px
  }

  /* .mainContainer{
      width: 100vw;
      
    } */
  .mainContainer>div {
    padding: 0.5rem;
  }

  .progress-bar {
    width: 90vw;

  }

  .formUserInfo {
    flex-direction: column;
    max-width: 40%;
  }

  /* .react-datepicker, .cardContainer, .seat-container  {
      width: 90vw;
    } */
}

@media(max-width: 768px) {

  /* .form-contain{
      justify-content: center;
    } */
  .custom-h2 {
    margin-bottom: 20px;
  }

  .reservation-f .customBtn {
    height: 50px;
    width: 117px !important;
  }

  .personne-n {
    display: none;
  }

  .form-reservation .second-btn {
    margin-left: 0;
    margin-top: 25px
  }

  .bottomContainer {
    flex-wrap: wrap;
    margin-top: 20px;
  }

  /* .formInput{
      width: 328px;
      margin-bottom: 20px;
    } */
  .iconBack {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    margin-left: 0;
  }

  .step-label {
    font-size: 12px;
  }

  .label-m {
    color: #eaeaea;
  }

  .react-datepicker {
    width: 300px !important;
    margin-top: 35px;
    /* margin-left: 15vw; */
    /* height: 100%; */
  }

  .custom-card {
    border: 1px solid black;
    margin-left: 20px;
    width: 80px;
    height: 60px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    margin: 20px 0 20px 20px;
  }

  .last-column {
    margin-right: 0;
  }

  .card:nth-child(1),
  .card:nth-child(4) {
    margin-left: 0;
  }

  .heure button {
    padding: 0 !important;
  }


  .container-m-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
  }

  .row-m-bar {
    display: flex;
  }

  .bloc-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 30px;
    /* margin-top: 45px; */
  }

  .bloc-bar p {
    font-size: 9px;
    font-weight: bold;
    margin-bottom: 9px;
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    color: #fff;
  }

  .circle.active {
    background-image: linear-gradient(to right, rgb(230, 177, 126), rgb(244, 182, 67), rgb(238, 170, 43), rgb(218, 143, 4));
    box-shadow: 0 4px 15px 0 rgba(254, 192, 76, 0.75);
    color: #fff;
    background-size: 300% 100%;
    font-weight: bold;
  }

  .circle.completed {
    background-color: rgb(238, 170, 43);
    font-size: smaller;
  }

  .line {
    flex: 1;
    height: 2px;
    background-color: #ccc;
    margin-top: 23px;
  }

  .line.completed {
    background-color: rgb(238, 170, 43);
  }


  .container-m-bar .row-m-bar:last-child .line {
    display: none;
  }

  #reservation-section {
    padding-top: 28px;
  }

  .card {
    width: 18rem;
  }

  .submit-book {
    margin: 15px auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .react-datepicker {
    width: 450px !important;
    margin-top: 35px;
    /* margin-left: 15vw; */
    /* height: 100%; */
  }

  .custom-card {
    border: 1px solid black;
    margin-left: 20px;
    width: 80px;
    height: 60px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    margin: 20px 0 20px 20px;
  }

  .seat-d-m {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .customProgress-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
  }

  .container-m-bar {
    display: none;
  }

  .bar-mobile {
    display: none;
  }

  .contain-card {
    margin: 22px 0;
  }

  .card {
    width: 30rem;
  }
}

/* Styles par défaut pour le div seat-d-m */
.seat-d-m {
  display: none;
}

/* Styles par défaut pour le div seat-tablet */
.seat-tablet {
  display: none;
}

/* Afficher le div seat-tablet sur les tablettes */
@media (min-width: 800px) and (max-width: 1024px) {
  .seat-d-m {
    display: none;
  }

  .seat-tablet {
    display: block;
  }
}

/* Masquer le div seat-tablet sur les appareils mobiles */
@media (max-width: 767px) {
  .seat-tablet {
    display: none;
  }

  .bar-d {
    display: none;
  }
}