.Identifier {
}

.inscription{
    background-color: black;
    color: white;
    padding: 5%;
}
.cnx{
    padding: 5%;
}

.but{
    margin-top: 15px;
}
.but:hover{
    background-color: #5b2317;
    color: white;
}
.lien {
    color:black;
}

.h2-center{
    text-align: center;
}
.identifier-form{
    font-size: 20px;
}

.form_top{
    padding-top: 20px;
}
@media (max-width: 768px){
    .text-2xl{
        font-size: 30px;
    }
    .form_top{
        padding-top: 6px;
    }
    /* .inscription{
        border-top: 3px solid rgb(218, 143, 4);
    } */
    .Identifier input[type=submit]{
        margin: 40px auto 0px;
        display: block;
    }
}
@media (max-width: 992px) {
    /* Règle CSS pour les écrans inférieurs à 576px de large (col-6) */
    .inscription {
      border-top: 3px solid rgb(218, 143, 4); /* Ajoutez le style de bordure souhaité */
    }
  }
  
  @media (min-width: 993px) {
    /* Règle CSS pour les écrans de 576px de large et plus (col-12) */
    .inscription {
      border-left: 3px solid rgb(218, 143, 4); /* Ajoutez le style de bordure souhaité */
    }
  }