#produits{
    margin-top: 50px;
}
.machine img{
    max-height: 190px;
}
.card-produit{
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    padding: 10px 10px 50px 10px;
    box-shadow: 0px 20px 18px -6px rgba(0,0,0,0.5);
    transition: transform 300ms ease 0ms;
    /* width: 27%; */
}
.card-produit{
    background-color: rgb(56, 55, 55);
}
.card-produit:hover {
    transform: scaleX(1.1) scaleY(1.1)!important;
}
@media (min-width:1000px){
    .card-produit{
        width: 27%;
    }
}