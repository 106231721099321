/* .Form{
    min-height: 75vh;
  } */
.room-card{
  pointer-events: none;
}
.room-card img{
  opacity: 0.4;
}
.max-img{
  max-height: 216.28px;
  min-width: 360px;
}

  .roomForm{
    display: flex;
    justify-content: space-between;
  }
.pointer{
  cursor: pointer;
}
.selected-title{
  text-decoration: underline; /* Activer le soulignement */
  text-decoration-color: rgb(238, 170, 43);
}
  /* .mainContainer{
    margin-bottom: 50px;
  } */
  
  /* .react-datepicker__header {
      background-color: black;
      color: white;
  } */
  .react-datepicker__month-container{
      /* width: 100%; */
      /* background-color: black; */
  }
  
  /* .react-datepicker__week{
      display: flex;
      justify-content: space-around;
  
  }
  
  .react-datepicker__day{
      color: black;
      background-color: white;
      border: 1px solid black;
      flex: 1;
  }
  .react-datepicker__day-names{
      display: flex;
      justify-content: space-around;
      border-top: 1px solid #D1A553;
  }
  
  .react-datepicker__current-month, .react-datepicker__day-name{
      color: white;
  }
  
  .react-datepicker__current-month{
      padding-bottom: 1rem;
  
  }
  
  .react-datepicker__navigation-icon--next::before, .react-datepicker__navigation-icon--previous::before {
      border-color: #D1A553;
  }
  .disabled-date {
      color: #999999;
      text-decoration: line-through;
      background-color: #f5f5f5;
  } */
  
  .react-datepicker__day--selected:hover{
    background-color: #D1A553;
  }
  #reservation-salle-section .card{
    width: 25rem;
  }
    
  .customBtn{
      background: #D1A553;
      border-radius: 8px;
      border: none;
      width: 150px;
      height: 50px;
      font-weight: bold;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      cursor: pointer;
      border: none;
      background-size: 300% 100%;
      font-size: 15px;
  }
  .customBtn:hover {
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    color: white;
  }
  
  .customBtn:focus {
    outline: none;
  }
  
  .customBtn {
    background-image: linear-gradient(to right, rgb(230,177,126), rgb(244, 182, 67), rgb(238, 170, 43), rgb(218, 143, 4));
    box-shadow: 0 4px 15px 0 rgba(254, 192, 76, 0.75);
  }
  .Form{
      /* display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center; */
      width: 100vw;
  
  }
  #reservation-salle-section .custom-h2{
    font-size:18px;
    color: rgb(218, 143, 4);
    margin-bottom: 30px;
  }
  #reservation-salle-section h4{
    color: #fff;
  }
  .form-reservation .customBtn{
    height: 40px;
    width: 200px;
  }
  #reservation-section select{
    height: 50px;
  }
  #reservation-section input {
    margin-bottom: 25px;
  }
  #reservation-section{
    padding-top: 28px;
  }
  /* .mainContainer{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 75vw;
      background-color: black;
      color: white;
  
      margin-top: 5vh;
      border-radius: 8px;
      padding: 1rem;
  } */
  .customContainer{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70vw;
      background-color: black;
  }
  .choix-salle h4{
    color: white;
    text-align: center;
  }
  .choix-salle .btn-outline{
    border: 2px solid rgb(238, 170, 43);
    color: white;
  }
  .btn-check:checked + .btn-outline {
    background-color: rgb(238, 170, 43);
    color: #fff; 
    border: 3px solid white;
  }
  .btn-check:hover + .btn-outline {
    background-color: rgb(238, 170, 43);
    color: #fff; 
  }
  /* #reservation-salle-section label.checked{
    background-color: rgb(238, 170, 43) !important;
    color: white !important;
  } */
  .selected-custom {
      background-color: #f5f5f5;
      font-weight: bold;
      border: 2px solid black;
    }
    .selected-image {
      border: 3px solid rgb(238, 170, 43);
      border-radius: 15px;
    }
  
    /* .customProgress-bar {
      display: flex;
      align-items: center;
      justify-content: center;
    } */
    
    /* .progress-step {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 80px;
      height: 60px;
      border-radius: 15%;
      gap: 10px;
      background-color: #eaeaea;
      color: #666;
      cursor: pointer;
      transition: background-color 0.3s ease;
    } */
    
    /* .progress-step.active {
      background-color: #D1A553;
      color: #fff;
      background-size: 300% 100%;
      background-image: linear-gradient(to right, rgb(230,177,126), rgb(244, 182, 67), rgb(238, 170, 43), rgb(218, 143, 4));
      box-shadow: 0 4px 15px 0 rgba(254, 192, 76, 0.75);
    }
    .progress-step.active:hover {
      background-position: 100% 0;
      -o-transition: all .4s ease-in-out;
      -webkit-transition: all .4s ease-in-out;
      transition: all .4s ease-in-out;
      color: white;
    }
    
    .progress-step.active:focus {
      outline: none;
    } */
    
   
    
    .step-circle {
      font-size: 18px;
    }
    
    .step-label {
      font-size: 12px;
      margin-top: 0;
      text-align: center;
    }
    
    .container-resume .card-title{
      margin-bottom: 20px;
    }
    
    .progress-step:not(:last-child) {
      margin-right: 20px;
    }
    
    .progress-bar-line:not(:last-child) {
      margin-right: -10px;
    }
    
  
    .formUserInfo{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5px;
      /* padding: 0.5rem; */
      
  
    }
  
    /* .formInput{
      border-radius: 8px;
      padding: 0.5rem;
      max-height: 40px;
    } */
  
  
    .btnContainer{
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  
    .resume{
      display: flex;
      justify-content: space-evenly;
      /* align-items: center; */
      width: 100%;
    }
  
    .detail{
      /* background-color: white;
      color: black; */
    }
    @media only screen and (max-width:1025px)  {
      .Form > h1{
        font-size: 20px;
      }
      .custom-h2{
        font-size:16px;
        /* margin-top: 20px; */
      }
      /* .mainContainer{
        width: 100vw;
      } */
      .mainContainer > div {
        padding: 0.5rem;
      }
      .progress-bar{
        width: 90vw;
      
      }
  
      .formUserInfo{
        flex-direction: column;
        max-width: 40%;
      }
  
      .react-datepicker, .seat-container  {
        width: 90vw;
      }
    }