 .formPayment {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }
  

/* Buttons and links */
.PaymentButton {
    background: #D1A553;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    margin-top: 15px;
  }
  
  .PaymentButton:hover {
    filter: contrast(115%);
  }
  
  .PaymentButton:disabled {
    opacity: 0.5;
    cursor: default;
  }

  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }
  
  #payment-element {
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 600px) {
    .formPayment {
      width: 80vw;
      min-width: initial;
    }
  }

  /* Media queries for medium-sized screens */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .formPayment {
      width: 50vw;
      min-width: initial;
    }
  }
