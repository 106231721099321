.Nvmdp {}
.mdp{
    padding: 5%;
}
.btn-orange{
    /* background-color: rgb(218, 143, 4) !important;
    color: white !important;
    margin-top: 5%;
    transition: background-color 0.8s ease !important; */
    /* width: 200px; */
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 45px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    margin: 0;

    border-radius: 50px;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.btn-orange:hover {
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    color: white;
}

.btn-orange:focus {
    outline: none;
}

.btn-orange {
    background-image: linear-gradient(to right, rgb(230,177,126), rgb(244, 182, 67), rgb(238, 170, 43), rgb(218, 143, 4));
    /* background-image: linear-gradient(to right, rgb(230,177,126), rgb(113, 76, 7), rgb(142, 93, 4), rgb(218, 143, 4)); */
    /* box-shadow: 0 4px 15px 0 rgba(254, 192, 76, 0.75); */
    /* box-shadow: 0px 5px 15px -2px rgba(254, 192, 76, 0.75); */
}
.loader {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid rgb(238, 170, 43);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Styles pour la boîte d'alerte */
  .alert-box {
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 35px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 999;
    max-width: 80%;
    display: flex;
    align-items: center;
  }
  .alert-box-spinner{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 4px;
    z-index: 999;
    max-width: 80%;
    display: flex;
    align-items: center;
  }
  