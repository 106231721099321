.Contact{
  /* display: flex; */
  /* justify-content: center; */
  /* min-height: 75vh; */
  padding: 1rem 0;
}
#contact{
  margin: 0;
}
.custom-btn {
  background: #D1A553;
  border-radius: 8px;
  border: none;  
  width: 150px; 
  height: 30px;
  color: white;
  display: flex; 
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
}

.mainContainer {
  /* display: flex; */
  /* justify-content: center; */
  flex-direction: column;
  /* align-items: center; */
  background-color: rgb(24, 23, 23);
  color: white;
  margin-top: 5vh;
  border-radius: 8px;
  /* width: 50vw; */
  padding: 1rem;
  margin-left: auto;
  margin-right: auto; 
  box-shadow: 10px 18px 15px -3px rgba(0,0,0,0.1);
}

.titleContainer {
  text-align: center; /* Centrer le texte */
  white-space: pre-line; /* Permettre le retour à la ligne */
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
}

.formContainer {
  display: grid;
  grid-template-columns: 1fr; /* Affichez les champs dans une seule colonne pour les appareils mobiles */
  gap: 1rem;
  width: 100%; /* Utilisez toute la largeur disponible */
}

.box {
  padding-bottom: 20px;
}

/* Champs */
.champs {
  box-sizing: border-box;
  width: 75%;
  height: 35px;
  background: #F9F2F2;
  mix-blend-mode: normal;
  border: 1px solid #000000; 
  border-radius: 8px;
}
 
/* Champs */
.message {
  box-sizing: border-box; 
  width: 80%;
  height: 232px;
  background: #F9F2F2;
  mix-blend-mode: normal;
  border: 1px solid #000000;
  border-radius: 8px;
} 

section{
  padding: 45px 0 80px 0;
}
#contact-form{
  font-size: 20px;
  background: rgb(24, 23, 23);
  padding: 40px;
  border-radius: 10px;
}
#contact label{
  color: #fff;
}
#contact input {
  margin-bottom: 25px;
}
.form-control{
  height: 50px;
  font-size: 18px;
}
#contact-form input[type=submit]{
  margin: 40px 0px;
  display: block;
}
.b{
  font-weight: bold;
}
#contact .customBtn{
  height: 50px;
  font-size: 15px;
}
@media (max-width: 768px){
  #contact-form input[type=submit]{
    margin: 40px auto 0px;
    display: block;
  }
  .alert-box {
    /* position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 999;
    max-width: 80%;
    display: flex;
    align-items: center; */
    width: 100%;
    padding: 5px !important;
  }
}

/* Styles pour l'icône de chargement */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid rgb(238, 170, 43);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Styles pour la boîte d'alerte */
.alert-box {
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 35px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 999;
  max-width: 80%;
  display: flex;
  align-items: center;
}
.alert-box-spinner{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 4px;
  z-index: 999;
  max-width: 80%;
  display: flex;
  align-items: center;
}

/* .alert-box.success {
  border: 2px solid #4CAF50;
  color: #4CAF50;
}

.alert-box.error {
  border: 2px solid #f44336;
  color: #f44336;
} */
.captcha{
  margin-top: 25px;
  margin-bottom: 15px;
}