*{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.o-h{
    overflow: hidden;
}
.rainbow-button {
    display: inline-block;
    padding: 10px 20px;
    font-weight: bold;
    width: 200px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: white;
    background-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.rainbow-button:hover {
    background-image: linear-gradient(to right, violet, indigo, blue, green, yellow, orange, red);
}
.img-mobile{
    /* width: 100%;
    height: 100vh;
    object-fit: cover; */
    width: 100%;
    height: auto;
    margin: 0;
}
.welcome-container {
    white-space: nowrap;
}
#about p{
    font-size: 19px;
}
p{
    font-size: 17px;
}
.text-overlay p {
    margin-bottom: 0;
}
#service p{
    font-size: 15px;
}
.coffee{
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
}
@keyframes scrollAnimation {
    0% {
      transform: translateY(0); /* Position de départ */
    }
    50% {
      transform: translateY(-15px); /* Position intermédiaire (descendre) */
    }
    100% {
      transform: translateY(0); /* Position intermédiaire (monter) */
    }
}
.div-chevron{
    animation: scrollAnimation 1s infinite ease-in-out;
}
@media (max-width: 991.2px) {
    /* Taille du bouton pour les écrans jusqu'à 768px de largeur */
    .welcome-container {
        white-space: normal;
    }
    .btn-orange{
        height: 35px !important;
        box-shadow: 0 0 0 0 rgba(254, 192, 76, 0.75) !important;
    }
    .bouton-main .btn{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bouton-main{
        align-items: center;
        justify-content: center;
        /* margin-top: 400px !important; */
    }
    #service ul{
        list-style: none;
    }
    /* .text-overlay{
        margin-top: 0 !important;
        text-align: center;  
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        padding: 20px;
        text-align: center; 
        margin-top: -50px;
    } */
      
    .text-top {
        margin-top: 0;
    }
    .text-image-container {
        position: relative;
        display: inline-block;
        background-color: rgb(161, 118, 78);
        height: calc(100vh - 80.650px);
    }
    .text-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: flex-start;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
        color: #fff;
        margin-top: 0;
    }
    /* .text-overlay {
        position: absolute;
        top: 10%;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        color: whitesmoke;
    } */
    .text-none{
        display: none;
    }
    .col-md-8 {
        max-width: 100%; /* Pour s'assurer que le texte ne dépasse pas la largeur de la div parent */
        margin: 0 auto; /* Pour centrer horizontalement le texte */
    }
    .img-main{
        width: 100%;
        height: calc(100vh - 80.650px);
        max-width: 100% !important;
        filter: brightness(0.6);
        position: relative;
        /* overflow: hidden; */
        /* box-shadow: 0px 45px 20px -3px rgba(0,0,0,0.1); */
    }

    section{
        text-align: center;
    }
    #divider-none{
        width: 250px;
        height: 2px;
        background: rgb(238, 170, 43);
        margin: 0 auto;
    }
    .professionel{
        margin-top: 35px;
    }
    .chevron{
        font-size: 30px;
        color:rgb(232, 174, 67);
        margin: 0;
    }
    .chevron svg:hover{
        font-size: 30px;
        color:whitesmoke;
    }
    .chevron svg path:hover{
        font-size: 30px;
        color:whitesmoke;
        /* border: 1px solid rgb(218, 143, 4); */
    }
    .div-chevron{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 35px;
    }
    .m-title{
        text-shadow: 4px 4px 0px rgba(255, 166, 0, 0.54);
        color: whitesmoke;
    }
    #service .mb{
        padding-bottom: 12px;
    }
    #forfaits ul span{
        display: none;
    }
}
@media (min-width: 991.2px) {
    .img-main{
        width: 100%;
        height: calc(100vh - 70px - 57.5px);
        max-width: 100% !important;
        filter: brightness(0.6);
    }
    .text-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        padding: 20px;
    }
    .chevron{
        font-size: 50px;
        color:rgb(232, 174, 67);
        margin: 0;
    }
    .chevron svg:hover{
        font-size: 50px;
        color:whitesmoke;
    }
    .chevron svg path:hover{
        font-size: 50px;
        color:whitesmoke;
        /* border: 1px solid rgb(218, 143, 4); */
    }
    .div-chevron{
        position: fixed;
        margin-top: 65px;
        left: 50%;
        transform: translateX(-50%);
    }
    #join #divider-none{
        display: none;
    }
    #join .btn-orange{
        height: 43px;
        padding-left: 25px;
        padding-right: 25px;
    }
    .professionel{
        border-left: 2px solid rgb(255, 193, 78);
    }
    /* .bouton-main .btn:nth-child(2) {
        margin-left: 15px;
    } */
    .m-none{
        display: none;
    }
    #service .mb{
        padding-bottom: 40px;
    }
    #service .divider{
        margin-bottom: 70px;
    }
    #join p{
        padding: 10px 20px 0;
    }
}

.btn-orange{
    /* background-color: rgb(218, 143, 4) !important;
    color: white !important;
    margin-top: 5%;
    transition: background-color 0.8s ease !important; */
    /* width: 200px; */
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 45px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    margin: 0;

    border-radius: 50px;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
/* .btn-orange:hover{
    background-color: #d5d1d1 !important;
    color: rgb(218, 143, 4) !important;
    border: 2px solid rgb(218, 143, 4);
} */
.btn-orange:hover {
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    color: white;
}

.btn-orange:focus {
    outline: none;
}

.btn-orange {
    background-image: linear-gradient(to right, rgb(230,177,126), rgb(244, 182, 67), rgb(238, 170, 43), rgb(218, 143, 4));
    /* background-image: linear-gradient(to right, rgb(230,177,126), rgb(113, 76, 7), rgb(142, 93, 4), rgb(218, 143, 4)); */
    /* box-shadow: 0 4px 15px 0 rgba(254, 192, 76, 0.75); */
    /* box-shadow: 0px 5px 15px -2px rgba(254, 192, 76, 0.75); */
}

.divider{
    width: 100px;
    height: 4px;
    border-radius: 100px 100px 100px 100px;
    background: rgb(218, 143, 4);
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 20px;
}
.divider-gold{
    width: 100px;
    height: 4px;
    border-radius: 100px 100px 100px 100px;
    background: rgb(209,165,83);
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 20px;
}
.divider-w{
    width: 100px;
    height: 2px;
    background: whitesmoke;
    margin: 0 auto;
    margin-bottom: 35px;
}
#service h4{
    /* border-bottom: 2px solid rgb(238, 170, 43); */
    text-decoration: underline;
    text-decoration-color: rgb(238, 170, 43);
}
#service ul li{
    font-size: 19px;
}
.lemon{
    color: rgb(255, 193, 78);
    font-size: larger;
}
.text-overlay .tcd{
    margin-top: 55px;
}
.text-overlay h1{
    color: whitesmoke;
    /* text-decoration-line: underline;
    text-decoration-color: rgb(218,143,4); */
}
#service h3{
    text-align: center;
    /* margin-top: 60px; */
}
.box-s{
    box-shadow: 5px 5px 22px 0px rgba(218, 143, 4, 0.7);
}
section{
    margin-bottom: 50px;
}
#join button .customBtn{
    border-radius: 8px;
    border: none;
    width: 150px;
    height: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
#join .mt{
    margin-top: 40px;
}
.card-forfait{
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    padding: 10px 10px 50px 10px;
    box-shadow: 0px 20px 18px -6px rgba(0,0,0,0.5);
    transition: transform 300ms ease 0ms;
    width: 20.5rem !important;
}
.card-forfait{
    background-color: rgb(56, 55, 55);
}
.card-forfait:hover {
    transform: scaleX(1.1) scaleY(1.1)!important;
}
#forfaits h5{
    color: white;
}
#forfaits ul{
    list-style: none;
    color: white;
    padding-left: 0;
    text-align: center;
}
#forfaits ul p{
    font-size: 17px;
}
#forfaits ul span{
    font-size: 20px;
    margin-right: 20px;
}
#forfaits ul hr{
    height: 10px;
}
#forfaits .card-img-top{
    border-bottom: 3px solid rgb(238, 170, 43);
}
.lien-forfait{
    color: rgb(255, 166, 1);
    font-size: 16px;
}
.mt-desc{
    margin-top: 100px;
}
.list-coffee{
    list-style: none;
    padding: 0;
    font-size: 17px;
}
#coffee p{
    font-size: 20px;
}
.link-home{
    text-decoration: none;
    color: white;
}
#coffee li{
    margin-bottom: 15px;
}
#custom-drink-section ol{
    font-weight: bold;
}
@media(min-width:576px){
    .bouton-main .btn:nth-child(2) {
        margin-left: 15px;
    }
}
.icon-tech{
    font-size: 80px;
    border: 3px solid rgb(255, 166, 1);
    border-radius: 50%;
    padding: 15px;
    margin-bottom: 10px;
}