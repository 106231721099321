
#list-group-tabs-example p{
    margin-right: 20px !important;
    text-align: center;
    height: calc(100vh - 80.650px);
}
.sec{
    margin-top: 100px;
}
.profile-section{
    min-height: 70vh;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
    color: rgb(97, 97, 96);
    font-weight: bold;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    color: rgb(218, 143, 4);
}
a.dropdown-item:focus{
    background-color: rgb(218, 143, 4);
}
.form-profil .btn-add-p{
    border: none;
}
.input-group-text{
    cursor: pointer;
}
.btn-update{
    background-color: #D1A553;
    color: white;
}
.btn-update:hover{
    background-color: #9f834c;
    color: white;
}
.pen-ico{
    font-size: small;
}
.annuler{
    color: transparent;
    text-shadow: 0 0 0 white;
    font-size: small;
}
.check-ico{
    color: rgb(4, 204, 4);
}
.sablier{
    color: rgb(218, 143, 4);
    font-size: smaller;
}
.disable-account{
    color: red;
}
.btn-alert-d{
    margin: 0 10px;
}