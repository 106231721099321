.border-r{
    border-right: 3px solid rgb(243, 162, 13);
}
#custom-drink h3{
    margin-bottom: 20px;
}
#custom-drink .form-check-input:checked{
    background-color: rgb(243, 162, 13);
    background-image: white;
    border-color: rgb(243, 162, 13);
}
.rainbow-text {
    /* font-size: 36px; */
    font-weight: bold;
    background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
    justify-content: center;
    /* padding: 10px 0;
    margin: 20px; */
    /* Créez un dégradé animé (facultatif) */
    background-size: 200% auto;
    animation: rainbow 5s linear infinite;
  }
  
  /* Animation du dégradé arc-en-ciel */
  @keyframes rainbow {
    0%, 100% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
.ia-resp {
    padding: 10px;
    border: 3px solid rgb(243, 162, 13);
    /* border: 3px solid transparent;
    border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    border-image-slice: 1; */
    border-radius: 20px;
    font-weight: 500;
}
@media(max-width:768px){
    .border-r{
        border-right: none;
        border-bottom: 1px solid rgb(243, 162, 13);
    }
}